/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useEffect, useContext, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import Modal from "../../components/Modal/Modal"
import { Menu } from "@headlessui/react"
import { Link } from "gatsby"
import LazyImage from "../../components/LazyImage"
import Skeleton from "../../components/Skeleton"
import { Table, Tr, Td } from "../../components/Table/TableElements"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import Pagination from "../../components/Pagination"
import ManageUserTable from "../../components/Table/ManageUserTable"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import StateContext from "../../context/state/StateContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import CircleAvatar from "../../components/Elements/CircleAvatar"
import { getMyAccount, getSubUserListing } from "../../helpers/DrupalHelper"
import ManageAccountForm from "../../components/Forms/ManageAccountForm"
import Button from "../../components/Elements/Button"
import NotificationContext from "../../context/Notification/NotificationContext"

const MenuSection = props => {
  return (
    <div className={`mb-6 ${props.className && props.className}`}>
      <MenuHead title={props.title} link={props.link} />
      {props.children}
    </div>
  )
}
const MenuHead = props => {
  const { title, link } = props
  return (
    <div className="pb-2 w-full">
      <span
        className=""
        css={css`
          text-transform: uppercase;
          color: #717171;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 0.02rem;
        `}
      >
        <Link to={link}>{title}</Link>
      </span>
    </div>
  )
}
const Meta = props => {
  return (
    <span className="text-reg-12 text-[#717171] !leading-3 bg-[rgba(0,0,0,.04)] rounded-[6px] h-[20px] inline-flex items-center px-2">
      {props.children}
    </span>
  )
}

function MyAccount() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  dashboardTrail.push({ text: "Manage Users", link: "#" })
  const { toggleNotification } = useContext(NotificationContext)
  const { state } = useContext(StateContext)
  const { authentication } = useContext(AuthenticationContext)
  const { companies, brands, retailers } = authentication.currentUserData
  const [data, setData] = useState(null)

  const onSave = (data, v) => {
    if (v === "edit") {
      toggleNotification({ content: "Changes are made Sucessfully" })
      setData(data)
    } else {
      toggleNotification({ content: data + " Failed", error: true })
    }
  }
  useEffect(async () => {
    // console.log(state)
    const data = await getMyAccount(state.token)
    // console.log(data)
    setData(data)
  }, [])
  const active = false
  return (
    <LayoutDashborad heading="My  Account" trail={dashboardTrail}>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-2/3 md:pr-[30px]">
          {data && (
            <ManageAccountForm
              token={state.token}
              data={data}
              onSave={onSave}
            />
          )}
        </div>
        <div className="pt-[30px] md:pt-0 md:w-1/3">
          <SectionCard title="Associations">
            <div className="">
              {companies.length > 0 && (
                <MenuSection title="Companies" link={"/dashboard/my-companies"}>
                  <Table flush>
                    {companies.map((item, k) => {
                      return (
                        <Tr>
                          <Td>
                            <div className="flex">
                              <div className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[70%]">
                                {item.title}
                              </div>
                              <div className="ml-auto">
                                <Meta>
                                  {item.association === "lead"
                                    ? "Manager"
                                    : "Employee"}
                                </Meta>
                              </div>
                            </div>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Table>
                </MenuSection>
              )}
              {brands.length > 0 && (
                <MenuSection title="Brands" link={"/dashboard/my-brands"}>
                  <Table flush>
                    {brands.map((item, k) => {
                      return (
                        <Tr>
                          <Td>
                            <div className="flex items-center">
                              <CircleAvatar
                                image={item.logo}
                                xs
                                border
                                shadowNone
                              />
                              <span className="pl-2">{item.title}</span>
                            </div>
                          </Td>

                          <Td className="float-right">
                            <Meta>
                              {item.association === "lead"
                                ? "Manager"
                                : "Employee"}
                            </Meta>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Table>
                </MenuSection>
              )}
              {retailers.length > 0 && (
                <MenuSection title="Retailers" link={"/dashboard/my-retailers"}>
                  <Table flush>
                    {retailers.map((item, k) => {
                      return (
                        <Tr>
                          <Td>
                            <div className="flex">
                              <div className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[70%]">
                                {item.title}
                              </div>
                              <div className="ml-auto">
                                <Meta>
                                  {item.association === "lead"
                                    ? "Manager"
                                    : "Employee"}
                                </Meta>
                              </div>
                            </div>
                            {/*<div>{item.title}</div>
                          </Td>
                          <Td className="float-right">
                            <Meta>
                              {item.association === "lead"
                                ? "Manager"
                                : "Employee"}
                            </Meta>*/}
                          </Td>
                        </Tr>
                      )
                    })}
                  </Table>
                </MenuSection>
              )}
            </div>
          </SectionCard>
        </div>
      </div>
    </LayoutDashborad>
  )
}
export default MyAccount

// /** @jsx jsx */
// import { css, jsx } from "@emotion/react"
// import React, { useContext, useEffect, useState } from "react"
// import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
// import SalesChart from "../../components/Elements/SalesChart"
// import LayoutDashborad from "../../components/Layout/Dashboard"
// import DashboardTable from "../../components/Table/DashboardTable"
// import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
// import DataContext from "../../context/Data/DataContext"
// import AuthenticationContext from "../../context/authentication/AuthenticationContext"
// import { getAssetListing, getMyOrdersReport } from "../../helpers/DrupalHelper"
// import StateContext from "../../context/state/StateContext"
// import Skeleton from "../../components/Skeleton"
// import Dashboard from "../../templates/Dashboard"
// import Pagination from "../../components/Pagination"
//

//
//
// function MySales() {
//   dashboardTrail.push({ text: "My Sales", link: "#" })
//   const { currentUserCompaniesAsOp } = useContext(DataContext).data
//   // console.log(authentication)
//   // console.lo("authentication")
//   // const[username, setUsername]= useState(null)
//   const { authentication } = useContext(AuthenticationContext)
//   const { currentUserData } = authentication
//   const {firstname} = currentUserData
//
//   // useEffect(() => {
//   //   console.log(authentication)
//   //   if(authentication?.currentUserData?.firstname) {
//   //     console.log(authentication?.currentUserData?.firstname)
//   //     console.log("authentication?.currentUserData?.firstname")
//   //     setUsername(authentication.currentUserData.firstname)
//   //   }
//   // },[authentication])
//   // const { currentUserData } = authentication
//   // console.log(authentication)
//   // console.log("currentUserData")
//
//   const { state } = useContext(StateContext)
//   // console.log(currentUserCompaniesAsOp)
//   let [select, setSelect] = useState(
//     currentUserCompaniesAsOp ? currentUserCompaniesAsOp[0] : false
//   )
//   const [data, setData] = useState(null)
//   useEffect(async () => {
//     if (select?.value) {
//       let data = await getMyOrdersReport(state.token, select.value)
//       // console.log(data)
//       setData(data)
//     }
//   }, [select])
//   // useEffect(async () => {
//   //   let data = await getMyOrdersReport(state.token, "1")
//   //   console.log(data)
//   //   setData(data)
//   // }, [])
//   useEffect(async () => {
//     if (currentUserCompaniesAsOp) {
//       setSelect(currentUserCompaniesAsOp[0])
//     }
//   }, [currentUserCompaniesAsOp])
//   return (
//     <LayoutDashborad heading={`Welcome, ${firstname}`} trail={dashboardTrail}>
//       {/*<Dashboard />*/}
//       <SalesChart />
//       <div className="py-6">
//         {currentUserCompaniesAsOp && (
//           <FloatingSelect
//             options={currentUserCompaniesAsOp}
//             defaultValue={currentUserCompaniesAsOp[0]}
//             label={"Choose company"}
//             onChange={v => setSelect(v)}
//           />
//         )}
//       </div>
//
//       <DashboardTable data={tabledata} />
//       <Pagination />
//
//       {data === null ? (
//         <Skeleton table />
//       ) : data.length > 0 ? (
//         <DashboardTable list={data} />
//       ) : (
//         "No items to display"
//       )}
//     </LayoutDashborad>
//   )
// }
//
// export default MySales
